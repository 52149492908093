.admin_login_css {
  margin-top: 90px !important;
}
.aws-p {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 5px 0;
}
.aws-p1 {
  margin: 0 0 0 0;
  font-size: 14px;
}
.card-admin {
  background: #fff;
  padding: 30px 20px;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border-radius: 12px;
  height: calc(100% - 30px);
  margin: 0 0 30px 0;
}
.aws-p {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 5px 0;
}
.aws-p1 {
  margin: 0 0 0 0;
  font-size: 14px;
}
.table-90 {
  width: 100%;
}
.card-table {
  background: #fff;
  padding: 30px 20px;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border-radius: 12px;
  overflow: auto;
  margin: 15px 0;
}
.table-90 thead tr th {
  font-size: 14px;
  background: #17c1e8;
  color: #fff;
  padding: 10px 15px;
}
.table-90 tbody tr td {
  font-size: 14px;
  background: #ffffff;
  color: #fff;
  padding: 10px 15px;
  color: #000;
}
.table-90 tbody tr:nth-child(even) td {
  background: #17c1e80d;
}
.card-admin {
  background: #fff;
  padding: 30px 20px;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border-radius: 12px;
}
.aws-p {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 5px 0;
}
.aws-p1 {
  margin: 0 0 0 0;
  font-size: 14px;
}
.table-90 {
  width: 100%;
}
.card-table {
  background: #fff;
  padding: 30px 20px;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border-radius: 12px;
  overflow: auto;
  margin: 15px 0;
}
.table-90 thead tr th {
  font-size: 14px;
  background: #17c1e8;
  color: #fff;
  padding: 10px 15px;
}
.table-90 tbody tr td {
  font-size: 14px;
  background: #ffffff;
  color: #fff;
  padding: 10px 15px;
  color: #000;
}
.table-90 tbody tr:nth-child(even) td {
  background: #17c1e80d;
}
.Search-input {
  background: #fff;
  padding: 0px 15px;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border-radius: 6px;
  border: none;
  height: 40px;
  margin: 0 15px 0 0;
}
.Search-bt {
  background: #17c1e8;
  padding: 0px 15px;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border-radius: 6px;
  border: none;
  height: 40px;
  margin: 0 15px 0 0;
  color: #fff;
  font-weight: bold;
}
.Search-div {
  margin: 30px 0 0 0;
}

.privacyPolicy_css {
  background: #fff;
  border: #0000;
  border-radius: 18px;
  box-shadow: 0 8px 24px #959da512;
  outline: none;
  padding: 40px;
}

.privacyPolicy_css h1 {
  color: #17c1e8;
  font-size: 40px !important;
  margin: 0 0 22px;
}
.privacyPolicy_css h2 {
  font-size: 20px !important;
  font-weight: 500;
}

.privacyPolicy_css p {
  color: #67748e;
  margin: 0 0 22px;
}

.card-title {
  color: #262626;
  font-size: 1.5em;
  line-height: normal;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0.5em;
}

.small-desc {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  color: #452c2c;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2em;
  height: 2em;
  overflow: hidden;
  top: 0;
  right: 0;
  background: linear-gradient(135deg, #6293c8, #384c6c);
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.dashboardCard {
  display: block;
  position: relative;
  max-width: 100% !important;
  max-height: initial;
  background-color: #f2f8f9;
  border-radius: 10px;
  padding: 2em 1.2em;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(to bottom, #c3e6ec, #a7d1d9);
  font-family: Arial, Helvetica, sans-serif;
  cursor: "pointer";
  width: 100%;
  height: calc(100% - 15px);
  text-align: right;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: "box-shadow 0.3s ease-in-out";
  margin: 0 0 0 0 !important;
}

/* .dashboardCard {
  display: block;
  position: relative;
  max-width: 100% !important;
  max-height: 320px;
  background-color: #f2f8f9;
  border-radius: 10px;
  padding: 2em 1.2em;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(to bottom, #c3e6ec, #a7d1d9);
  font-family: Arial, Helvetica, sans-serif;
  cursor: "pointer";
  width: 94%;
  height: 45vh;
  text-align: right;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: "box-shadow 0.3s ease-in-out";
} */

.dashboardCard:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: linear-gradient(135deg, #364a60, #99b3dc);
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.35s ease-out;
}

.dashboardCard:hover:before {
  transform: scale(28);
}

.dashboardCard:hover .dashboardCard-desc {
  transition: all 0.5s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.dashboardCard:hover .dashboardCard-title {
  transition: all 0.5s ease-out;
  color: #ffffff;
}

.dashboardCard-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.faq-list {
  margin-top: 20px;
}

.faq-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.faq-text {
  margin: 0;
  flex-grow: 1;
}

.faq-actions {
  display: flex;
  gap: 10px;
}

.faq-input,
.faq-textarea {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}

.faq-button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-button:hover {
  background-color: #007bff;
  color: white;
}

.edit-button {
  background-color: #ffcc00;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
}

.save-button {
  background-color: #28a745;
  color: white;
}

.qr-web-container {
  text-align: center;
  margin: 20px;
}

.qr-header {
  margin: 20px 0;
}

.logo {
  width: 50px;
}
.logoqr {
  width: 100px;
}

.main-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  margin-top: 30px;
}

.instructions {
  text-align: left;
  max-width: 400px;
}

.instructions ol {
  padding-left: 20px;
}

.instructions a {
  display: block;
  margin-top: 10px;
  color: #0088cc;
  text-decoration: none;
}

.instructions a:hover {
  text-decoration: underline;
}

.qr-code-container {
  text-align: center;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.checkbox-container {
  margin-top: 10px;
}

.footer {
  margin-top: 50px;
  color: gray;
  font-size: 14px;
}

.tiktok {
  text-align: center;
  color: white;
  background-color: #000;
  border-radius: 20px;
}

@media (max-width: 767px) {
  .faq_css {
    width: 90%;
    padding: 30px 30px;
  }

  .main-content {
    flex-wrap: wrap;
  }
}

.tiktoklogo {
  width: 100px;
}

.home-page {
  min-height: 100vh;
  background-color: #f5f5f5;
}

.header {
  display: flex;
  align-items: center;

  color: white;
  padding: 0 20px;
}

.header .logo img {
  margin-right: 20px;
}

.header-title {
  color: white !important;
  margin: 0;
}

.content {
  padding: 50px;
  text-align: center;
}

.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

.hero-image {
  max-width: 300px;
  border-radius: 10px;
}

.hero-text {
  max-width: 500px;
  text-align: left;
}

.footer {
  color: white;
  padding: 0 20px;
}

@media (max-width: 576px) {
  .ant-avatar {
    width: 60px !important;
    height: 60px !important;
  }

  .ant-typography-title {
    font-size: 16px !important;
  }

  .ant-btn {
    font-size: 12px !important;
  }
}

.UserChat_css {
  background: #f9f9f9;
  padding: 5px;
  display: flex;
  border-radius: 10px;
  box-shadow: 3px 3px 5px #c2cdd0;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  position: relative;
}
.UserChat_css p {
  margin: 0px;
}

.UserChat_css img {
  height: 50px;
  width: 60px;
}

.chat_img_css {
  position: relative;
  left: 0;
}

.userchat_text_css {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 215px;
  display: inline-block;
}

.history_css {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.user_history_dropdown_css {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Subscription Container */
.subscription-container {
  text-align: center;
  padding: 20px;
}

.subscription-heading {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
}

/* Card Container */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Subscription Card */
.subscription-card {
  width: 280px;
  margin: 20px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.subscription-card:hover {
  transform: scale(1.05);
}

.card-icon {
  margin-bottom: 20px;
}

.bronze-icon {
  font-size: 48px;
  color: #cd7f32;
}

.silver-icon {
  font-size: 48px;
  color: #c0c0c0;
}

.gold-icon {
  font-size: 48px;
  color: #ffd700;
}

.platinum-icon {
  font-size: 48px;
  color: #e5e4e2;
}

.black-icon {
  font-size: 48px;
  color: #fff;
}

.card-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.black-card .card-title {
  color: #fff;
}

.card-description {
  margin-top: 10px;
}

.card-text {
  white-space: pre-line;
  font-size: 16px;
  color: #555;
}

.card-text_Black {
  white-space: pre-line;
  font-size: 16px;
  color: #fff;
}

.black-card .card-text {
  color: #eee;
}

.card-price {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
  color: #333;
}

.black-card .card-price {
  color: #fff;
}

.card-button {
  width: 100%;
  border-radius: 8px;
  font-weight: bold;
}

.bronze-button {
  background-color: #1890ff;
  border-color: #1890ff;
}

.silver-button {
  background-color: #1890ff;
  border-color: #1890ff;
}

.gold-button {
  background-color: #1890ff;
  border-color: #1890ff;
}

.platinum-button {
  background-color: #1890ff;
  border-color: #1890ff;
}

.black-button {
  background-color: #333;
  border-color: #333;
}

.toggle-black-card {
  margin-top: 30px;
  background-color: #333;
  border-color: #333;
  border-radius: 8px;
  font-weight: bold;
  padding: 0 24px;
  height: 40px;
}

.subscription-tabs {
  margin-bottom: 20px;
}

.subscription-tabs .ant-tabs-tab {
  font-size: 16px;
  font-weight: bold;
}

.subscription-tabs .ant-tabs-tab-active {
  color: #1890ff;
}

.subscription-container {
  padding: 20px;
}

.create-plan-button {
  margin-bottom: 20px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.subscription-card {
  width: 300px;
  border-radius: 10px;
  color: white;
}

.card-icon {
  font-size: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
}

.card-text {
  font-size: 14px;
}

.card-price {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.card-button {
  width: 100%;
}

.toggle-black-card {
  margin-top: 20px;
}

.subscription-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.edit-form {
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
}

.edit-form label {
  display: block;
  margin-bottom: 10px;
}

.edit-form input,
.edit-form textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

.edit-form button {
  margin-right: 10px;
}

.card-buttons {
  display: flex;
  gap: 10px;
}

.card-buttons .card-button {
  flex: 1;
}

.black-card-text{
  color: white !important;
}
