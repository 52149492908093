.wallet-container {
  display: inline-block;
  position: relative;
}

.wallet-address {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, box-shadow 0.3s;
  border: 1px solid transparent;
}

.wallet-address:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-color: #003d7a;
}

.wallet-address:focus {
  outline: none;
}

.copy-message {
  position: absolute;
  top: 40px;
  left: 100px;
  transform: translateX(-50%);
  margin-top: 8px;
  background-color: #19c742;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}
